(function () {
  'use strict';

  angular
      .module('neo.home.teams.newTeam')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.teams.newTeam', {
          url: '/new-team',
          templateUrl: 'home/teams/new-team/new-team.tpl.html',
          controller: 'NewTeamCtrl',
          controllerAs: 'vm',
          params: {
            entity: undefined
          },
          resolve: {
            genders: function (Genders) {
              return Genders.query().$promise;
            },
            programmes: function (Programmes) {
              return Programmes.query().$promise;
            },
            entities: function (Entitats) {
              return Entitats.query().$promise;
            }
          }
        });
  }
}());
